<template>
    <div class="bg-[#F8F8FA] py-[6%] px-[5%] flex justify-between">
        <div class="text-left w-[45%] mt-[5%]">
            <h1 class="text-[16px] text-[#3361FF] uppercase font-[600] my-[3%]">Leading skills assessments</h1>
            <h1 class="text-[38px] font-[600]">Pre-employment assessments are more than just a shortlisting tool with GO PLATFORM</h1>
            <p class="text-[14px] my-[3%]">
                GO PLATFORM skills assessments test a range of skills in one engaging, user-friendly flow. Unlock the hidden potential of candidates and remove unnecessary offline stages from your
                hiring process. Increase productivity and make decisions confidently with complete transparency around candidate scoring with data-rich profiles that make it simple to compare top
                performers.
            </p>
            <img loading="lazy" decoding="async" src="@/assets/Images/candidate_evaluation.png" alt="" class="w-full border-2 rounded border-[#D9D9D9] aspect-auto" />
        </div>
        <div class="w-[45%]">
            <div class="flex items-center gap-8 my-8" v-for="step in steps" :key="step.id">
                <span class="text-[40px] font-[700]">{{ step.id }}</span>
                <div class="px-6 py-8 bg-[#fff] rounded-md text-left shadow-card">
                    <h1 class="text-[20px] font-[600] mb-4">{{ step.title }}</h1>
                    <p class="text-[14px] font-[400]">{{ step.description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LeadingSkills",
    data() {
        return {
            steps: [
                {
                    id: 1,
                    title: "Build your ideal assessment",
                    description:
                        "Assessments can be created using the builder, write your own questions or choose from our bank of 300,000+, or you can use a template from our assessment library of 300+ verified tests.",
                },
                {
                    id: 2,
                    title: "Invite and track candidate progress",
                    description: "Easily invite candidates via email, or generate a quick link for your job ad, career page or social channel to track candidate source.",
                },
                {
                    id: 3,
                    title: "Optimize your assessment",
                    description: "Train our AI to understand what good and bad answers look like for you so we’ll grade your candidates like you would.",
                },
                {
                    id: 4,
                    title: "Select what really matters",
                    description: "Candidate results come to life with scores, rank, trophies and top performer badges, allowing you to unearth what’s most important to you.",
                },
                {
                    id: 5,
                    title: "Hire your hidden gem",
                    description:
                        "Once you’ve found the perfect candidate for your role, mark them as “Hired.” Your successful candidate report cards are then easily accessible in your company overview.",
                },
            ],
        };
    },
};
</script>
