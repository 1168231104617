<template>
    <div class="fixed inset-0 flex items-center justify-center z-50 mainDiv" v-if="this.Store.showWindow">
        <div class="bg-white rounded-lg shadow-lg p-6 w-full">
            <img loading="lazy" decoding="async" :src="require(`@/assets/onBoardingGIFs/${sections[currentSection].image}`)" :alt="sections[currentSection].title" class="w-[100%] mx-auto mb-4" />
            <h2 class="text-lg font-bold text-gray-800 mb-2">
                {{ sections[currentSection].title }}
            </h2>
            <p class="text-gray-700 mb-4">
                {{ sections[currentSection].content }}
            </p>
            <div class="flex w-full justify-center">
                <span
                    v-for="(section, index) in sections"
                    :key="index"
                    class="h-2 w-2 rounded-full mx-1"
                    :class="{
                        'bg-gray-800': currentSection === index,
                        'bg-gray-300': currentSection !== index,
                    }"
                ></span>
            </div>
            <div class="flex justify-between mt-4">
                <button @click="closeWindow" class="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline">{{ $t("Close") }}</button>
                <div class="w-[30%] flex justify-between btnWrapper">
                    <button
                        @click="previousSection"
                        :disabled="currentSection === 0"
                        class="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
                    >
                        Previous
                    </button>

                    <button
                        @click="nextSection"
                        :disabled="currentSection === sections.length - 1"
                        class="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from "../store/index";

export default {
    props: {
        showWindow: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    data() {
        return {
            currentSection: 0,
            display: false,
            sections: [
                {
                    title: "Create a project",
                    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam eget bibendum bibendum, arcu nunc bibendum dolor, vel aliquam velit nunc vel nisi.",
                    image: "newProject.gif",
                },
                {
                    title: "Add assessments",
                    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam eget bibendum bibendum, arcu nunc bibendum dolor, vel aliquam velit nunc vel nisi.",
                    image: "createAssessment.gif",
                },
                {
                    title: "Invite team members",
                    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam eget bibendum bibendum, arcu nunc bibendum dolor, vel aliquam velit nunc vel nisi.",
                    image: "inviteCoworker.gif",
                },
                {
                    title: "Invite candidates",
                    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam eget bibendum bibendum, arcu nunc bibendum dolor, vel aliquam velit nunc vel nisi.",
                    image: "inviteCandidate.gif",
                },
            ],
        };
    },
    methods: {
        nextSection() {
            this.currentSection++;
        },
        previousSection() {
            this.currentSection--;
        },
        closeWindow() {
            this.Store.showWindow = false;
            localStorage.setItem("windowShown", "false");
            this.display = JSON.parse(localStorage.getItem("windowShown"));
        },
        getImgUrl(imgUrl) {
            return `${imgUrl}`;
        },
    },
    mounted() {
        this.display = JSON.parse(localStorage.getItem("windowShown"));
    },
};
</script>

<style scoped lang="scss">
.mainDiv {
    background-color: rgba(0, 0, 0, 0.5);
}
button {
    background-color: transparent;
    border: 1px solid #2196f3;
    color: #2196f3;

    &:hover {
        background-color: #2196f3;
        color: #fff;
    }
}

.btnWrapper {
    button {
        background-color: transparent;
        border: 1px solid #2196f3;
        color: #2196f3;

        &:disabled {
            background-color: #e0e0e0;
            color: #9e9e9e;
            border: 1px solid #e0e0e0;
            cursor: not-allowed;

            &:hover {
                background-color: #e0e0e0;
                color: #9e9e9e;
                border: 1px solid #e0e0e0;
            }
        }
    }

    button:hover {
        background-color: #2196f3;
        color: #fff;
    }

    > :nth-child(2) {
        background-color: #2196f3;
        color: #fff;

        &:hover {
            background-color: #1976d2;
        }
    }
}
</style>
