<template>
    <div class="task-card" :id="id" :draggable="true">
        <div>
            <span>{{ task.cardTitle }}</span>
            <button class="edit-btn" @click="editTask(this.task)">
                <img loading="lazy" decoding="async" src="@/assets/Images/icons/icon_edit.svg" width="20" height="20" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "KanbanCard",
    props: {
        task: { type: Object },
        editTask: { type: Function },
        id: { type: String },
    },
    methods: {
        /* dragStart: e=>{
                const target = e.target;
                //e.dataTransfer.setData('task.id', target.id);
                setTimeout(()=>{ target.style.display = "none"; }, 0);
            },
            dragEnd: e=>{
                e.target.style.display= "block";
            },
            dragOver: e =>{
                e.stopPropagation();
            },*/
    },
};
</script>

<style lang="scss" scoped>
.task-card {
    padding: 5%;
    background: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    &:hover .edit-btn {
        opacity: 1;
    }
    > :first-child {
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
            opacity: 0;
            border: none;
            background: none;
        }
    }
    > :nth-child(2) {
        display: none;
    }
}
</style>
